/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import * as Sentry from '@sentry/react';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import LayoutStyles from '../Components/Layout/Styles/LayoutStyles';

const styles = () => ({
  layout: {
    ...LayoutStyles.centered800Layout,
    paddingTop: '250px',
  },
  subtitle: {
    paddingTop: '20px',
  },
});

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Mettez à jour l'état, de façon à montrer l'UI de repli au prochain rendu.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, { extra: errorInfo });
    this.setState({ hasError: true });
  }

  render() {
    const { classes } = this.props;
    if (this.state.hasError) {
      // Vous pouvez afficher n'importe quelle UI de repli.
      return (
        <Grid container spacing={4} className={classes.layout}>
          <Grid item md={12} sm={12} xs={12}>
            <Typography variant="h3" component="h3" align="center" gutterBottom>
              Erreur d&apos;application
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              className={classes.subtitle}
            >
              Une erreur est survenue. Veuillez réessayer d&apos;ici quelques instants.
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              className={classes.subtitle}
            >
              Merci de votre compréhension.
            </Typography>
            <Typography
              variant="h6"
              component="h6"
              align="center"
              gutterBottom
              className={classes.subtitle}
            >
              L&apos;équipe Wello
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}

export default withStyles(styles)(ErrorBoundary);
